.custom-input-container{
  margin-bottom: 1rem;
}
.custom-input-label {
  color: white;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.1em;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 5px;
}

.info-icon{
  cursor: pointer;
}

.custom-input {
  width: 100%;
  height: 48px;
  border-radius: 6px;
  border: 1px;
  padding-left: 0rem;
  margin-top: 1rem;
}
.custom-input:focus {
  outline: 1px solid #4CAF50;
}

.error-text{
  margin-top: 5px !important;
  margin-bottom: 0px !important;
  font-size: 16px;
  font-weight: 500;
  color: red;
  padding-left:5px;
}

.custom-input-div{
  position: relative;
 
}
img.custom-input-start-icon {
  position: absolute;
  top: 30px;
  left: 15px;
  width: 21px;
}
.custom-input-end-icon{
  position: absolute;
  right: 20px;
  top: 30px;
}

.cursor-pointer{
  text-indent: 60px;
}

