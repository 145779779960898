.accordian{
    margin-bottom: 20px !important;
}
.wrap-services .MuiPaper-root.MuiPaper-elevation.accordian {
    border-radius: 10px !important;
    border: 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    border: 1px solid #dedede;
    color: #fff;
}
.wrap-services .MuiPaper-root.MuiPaper-elevation.accordian::before {
    display: none;
}
.wrap-services .MuiButtonBase-root.MuiAccordionSummary-root{
    border: 0 !important;
    border-radius: 0 !important;
    height: 55px;
}
.wrap-services .MuiAccordionSummary-content{
    margin: 0 !important;
}
.accordian:first-of-type{
    margin-top: 0px !important;
} 
.wrap-check-box{
    display: flex;
    align-items: center;
    gap: 15px;
    text-transform: capitalize;
    padding-left: 20px;
}
svg.drop-icons {
    color: #4CAF50;
}
.accordion-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}

.wrap-actions{
    width: 30%;
    display: flex;
    align-items: center;
    gap:10px;
}
.drop-icons-button{
    flex-direction: row-reverse;
}
@media only screen and (max-width: 1299px) {
    .wrap-actions{
        width: 60%;
    }
  }
   
 @media only screen and (max-width: 767px) {
    .wrap-actions{
        width: 70%;
    }
  }
   
   
